<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting journey_wpr">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="left_bar">
                <div class="acton_header mb-4">
                    <div class="left_section">
                        <div class="section_header">
                            <h2>Journey</h2>
                        </div>
                    </div>
                </div>
                <div class="trigger_wpr">
                    <ul>
                        <li v-for="(trigger, t) of journey.trigger" :key="t">
                            <div class="trigger_card" :class="{ 'pb-0': triggerScale != t }">
                                <div class="trigger_info" :class="{ active: selectedTriggerIndex == t}" @click="selectTrigger(t)">
                                      <button type="button" class="remove_btn" @click="deleteTrigger(t)">
                                          <i class="fas fa-trash-alt"></i>
                                      </button>
                                      <div class="icon"><i class="fas fa-bolt"></i></div>
                                      <h5>{{ triggerTitle(journey.trigger_type[t]) }}</h5>
                                      <div class="info">
                                          <h4 v-if="!trigger">Define Trigger</h4>
                                          <div v-if="journey.trigger_type[t] != 4">
                                              <p v-if="triggerName(trigger, journey.trigger_type[t])">{{ triggerName(trigger, journey.trigger_type[t]) }}</p>
                                              <h4 v-else>Define Trigger</h4>
                                          </div>
                                          <div v-else v-html="triggerUrlHtml(trigger)"></div>
                                      </div>
                                </div>
                                <div class="step_type trigger_add" v-if="triggerScale == t">
                                    <button class="add_new" @click="addAction($event)"><i class="fas fa-plus"></i></button>
                                    <div class="add_types">
                                        <h3>Add a next step to your Journey</h3>
                                        <ul>
                                            <li>
                                                <div class="card_item" @click="editWarning('delay')">
                                                    <i class="far fa-clock"></i>
                                                    <h4>Add Delay</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="card_item" @click="editWarning('condition')">
                                                    <i class="fas fa-code-branch"></i>
                                                    <h4>Add Condition</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="card_item" @click="editWarning('action')">
                                                    <i class="fas fa-cog"></i>
                                                    <h4>Add Action</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div v-if="triggerScale == t">
                                <draggable v-model="journey.action" tag="ul" item-key="" class="journey_list" :animation="200">
                                    <template #item="{element, index}">
                                        <span>
                                            <li>
                                                <div class="step_info mail" :class="{ 'active' : selectedActionIndex === index }" @click="selectAction(index, 'action', element)">
                                                    <i class="fas fa-cog"></i>
                                                    <h4>{{ actionTitle(journey.action_type[index]) }}</h4>
                                                    <div class="msg_cont">
                                                        <div class="action_details">
                                                            <h4 v-if="!element">Define Action</h4>
                                                            <label class="info">
                                                                <i class="fas fa-info"></i>
                                                                <div class="quick_info">{{ journey.action_title[index] ? journey.action_title[index] : 'No notes' }}</div>
                                                            </label>
                                                            <div v-if="journey.action_type[index] == 10">
                                                                <div class="mail_box">
                                                                    <div class="msg_desc" :class="{'border-0' : element.email_subject}">
                                                                        <p v-html="element.email_subject ? element.email_subject : 'Define Email'"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="journey.action_type[index] != 4">
                                                                <p v-if="actionName(element, journey.action_type[index])">{{ actionName(element, journey.action_type[index]) }}</p>
                                                                <h4 v-else>Define Action</h4>
                                                            </div>
                                                            <div v-else>
                                                                <ul>
                                                                    <li>{{ typeof element.email_to == 'string' ? element.email_to : element.email_to.join(',') }}</li>
                                                                </ul>
                                                                <div class="mail_box" v-if="element.send_method == 1 || element.send_method == 3">
                                                                    <div class="msg_desc" :class="{'border-0' : element.email_subject}">
                                                                        <p v-html="element.email_subject ? element.email_subject : 'Define Email'"></p>
                                                                    </div>
                                                                </div>
                                                                <div class="sms_box" v-if="element.send_method == 2 || element.send_method == 3">
                                                                    <div class="msg_desc" :class="{'border-0' : element.sms_content}">
                                                                        <p v-html="element.sms_content ? element.sms_content : 'Define Message'"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="remove_btn" @click="deleteTrigger(t)"><i class="fas fa-trash-alt"></i></button>
                                                </div>
                                                <div class="step_type">
                                                    <button class="add_new" @click="journey.action_delay[index] && journey.action_delay[index].status == 0 ? addAction($event) : ''">
                                                        <i class="fas fa-plus" v-if="journey.action_delay[index] && journey.action_delay[index].status == 0"></i>
                                                        <i class="fas fa-arrow-down" v-else></i>
                                                    </button>
                                                    <div class="add_types">
                                                        <h3>Add a next step to your Journey</h3>
                                                        <ul>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('delay')">
                                                                    <i class="far fa-clock"></i>
                                                                    <h4>Add Delay</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('condition')">
                                                                    <i class="fas fa-code-branch"></i>
                                                                    <h4>Add Condition</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('action')">
                                                                    <i class="fas fa-cog"></i>
                                                                    <h4>Add Action</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li v-if="journey.action_delay[index] && journey.action_delay[index].status == 1">
                                                <div class="step_info timer" :class="{ 'active' : selectedDelayIndex === index }" @click="selectAction(index, 'delay', element)">
                                                    <i class="fas fa-clock"></i>
                                                    <h4>{{ delayTitle(index) }}</h4>
                                                    <button type="button" class="remove_btn" @click="deleteTrigger(t)"><i class="fas fa-trash-alt"></i></button>
                                                </div>
                                                <div class="step_type">
                                                    <button class="add_new" @click="journey.action_delay[index] && journey.action_delay[index].condition == -1 ? addAction($event) : ''">
                                                        <i class="fas fa-plus" v-if="journey.action_delay[index] && journey.action_delay[index].condition == -1"></i>
                                                        <i class="fas fa-arrow-down" v-else></i>
                                                    </button>
                                                    <div class="add_types">
                                                        <h3>Add a next step to your Journey</h3>
                                                        <ul>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('delay')">
                                                                    <i class="far fa-clock"></i>
                                                                    <h4>Add Delay</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('condition')">
                                                                    <i class="fas fa-code-branch"></i>
                                                                    <h4>Add Condition</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('action')">
                                                                    <i class="fas fa-cog"></i>
                                                                    <h4>Add Action</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li v-if="journey.action_delay[index] && journey.action_delay[index].condition == 1" class="branch">
                                                <div class="branch_wpr">
                                                    <div class="branch_card" :class="{ 'active' : selectedConditionIndex === index }" @click="selectAction(index, 'condition', element)">
                                                        <span><i class="fas fa-code-branch"></i></span>
                                                        <div class="branch_info">
                                                            <h4>Set Condition</h4>
                                                            <button type="button" class="remove_btn" @click="deleteTrigger(t)"><i class="fas fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li class="no-before"></li>
                                                    </ul>
                                                </div>
                                                <div class="step_type">
                                                    <button class="add_new" @click="addAction($event)"><i class="fas fa-plus"></i></button>
                                                    <div class="add_types">
                                                        <h3>Add a next step to your Journey</h3>
                                                        <ul>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('delay')">
                                                                    <i class="far fa-clock"></i>
                                                                    <h4>Add Delay</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('condition')">
                                                                    <i class="fas fa-code-branch"></i>
                                                                    <h4>Add Condition</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click="editWarning('action')">
                                                                    <i class="fas fa-cog"></i>
                                                                    <h4>Add Action</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </span>
                                    </template>
                                </draggable>
                            </div>
                        </li>
                    </ul>
                    <div class="new_trigger">
                        <button class="add_new" @click="editWarning('trigger')"><i class="fas fa-plus"></i></button>
                    </div>
                    <div class="new_trigger left">
                        <button class="add_new" @click="editWarning('trigger')"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
            </div>
            <div class="tabs_content" :class="{ show: selectedTriggerIndex != null || selectedActionIndex != null || selectedDelayIndex != null || selectedConditionIndex != null }">
                <form class="forms-setting-form">
                    <button type="button" class="close_btn" @click="closeTriggerActionPanel()"><i class="fas fa-long-arrow-alt-right"></i></button>
                    <div class="step_details">
                        <div class="journey_details">
                            <div class="acton_header mb-4 d-block">
                                <div class="flex-space-between-center">
                                    <div class="left_section">
                                        <div class="section_header">
                                            <h2 v-if="selectedTriggerIndex != null">Trigger</h2>
                                            <h2 v-if="selectedActionIndex != null">Action</h2>
                                            <h2 v-if="selectedDelayIndex != null">Delay</h2>
                                            <h2 v-if="selectedConditionIndex != null">Condition</h2>
                                        </div>
                                    </div>
                                    <div class="right_section" v-if="selectedActionIndex != null && (journey.action_type[selectedActionIndex] == 4 || journey.action_type[selectedActionIndex] == 7 || journey.action_type[selectedActionIndex] == 10)">
                                        <label for="preview_message" class="switch_option capsule_btn p-0 border-0">
                                            <h5 class="mr-3">Full View</h5>
                                            <input type="checkbox" id="preview_message" v-model="fullPreviewMessage" hidden />
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                                <small class="text-danger">Classic OM journeys can not be updated in Thrive Coach. Please recreate if you would like to make changes.</small>
                            </div>
                            <div class="setting_wpr" v-if="selectedTriggerIndex != null">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Select Trigger</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="journey.trigger_type[selectedTriggerIndex]"
                                                :options="triggers"
                                                placeholder="Select One.."
                                                disabled
                                                :searchable="true"
                                            ></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 0 || journey.trigger_type[selectedTriggerIndex] == 1">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Sequence</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex]"
                                                    :options="sequences"
                                                    placeholder="Select One.."
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    disabled
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 2 || journey.trigger_type[selectedTriggerIndex] == 3">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Tag</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex]"
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    :group-select="true"
                                                    :options="tagsGroup"
                                                    :groups="true"
                                                    group-label="type"
                                                    group-options="tags"
                                                    :group-hide-empty="true"
                                                    placeholder="Select Tags"
                                                    disabled
                                                >
                                                    <template v-slot:grouplabel="{ group }">
                                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ option.name }}</span>
                                                    </template>
                                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                          {{ option.name }}
                                                          <span
                                                            v-if="!disabled"
                                                            class="multiselect-tag-remove"
                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                          >
                                                              <span class="multiselect-tag-remove-icon"></span>
                                                          </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Link</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="selectedTriggerLink.id"
                                                    :groups="true"
                                                    :searchable="true"
                                                    :options="triggerLinks"
                                                    valueProp="id"
                                                    label="title"
                                                    placeholder="Select link"
                                                    :groupSelect="false"
                                                    :groupHideEmpty="true"
                                                    disabled
                                                >
                                                    <template v-slot:option="{ option }">
                                                         <span v-if="option.$isLabel">
                                                             {{ option.$groupLabel }}
                                                         </span>
                                                         <span v-else>{{ option.title }}</span>
                                                   </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Name</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Sample link goes here" v-model="selectedTriggerLink.title" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Destination URL</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="https://onboardme.io" v-model="selectedTriggerLink.public_url" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <p>You can then select this link from a list of Automation Triggers in any course or broadcast. When a subscriber clicks the link the rule will be triggered and the action will run.</p>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 5">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select form</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex].trigger"
                                                    :options="forms"
                                                    placeholder="Select One.."
                                                    label="survey_name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    disabled
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 6 || journey.trigger_type[selectedTriggerIndex] == 7">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select task</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex]"
                                                    :options="tasks"
                                                    placeholder="Select One.."
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    disabled
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 8">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select progress scenario</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex]"
                                                    :options="progressTrackings"
                                                    placeholder="Select One.."
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    disabled
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="journey.trigger_type[selectedTriggerIndex] == 9">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select habit scenario</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="journey.trigger[selectedTriggerIndex]"
                                                    :options="habitTrackings"
                                                    placeholder="Select One.."
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    disabled
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr" v-if="selectedActionIndex != null">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Select Action</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="journey.action_type[selectedActionIndex]"
                                                :options="actions"
                                                placeholder="Select One.."
                                                disabled
                                                :searchable="true"
                                            ></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 0">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select Sequence</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].seq_id"
                                                        :options="sequences"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="journey.action[selectedActionIndex].seq_id">
                                            <label for="start_msg" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Start At Message 1</h5>
                                                <input type="checkbox" id="start_msg" :true-value="1" :false-value="0" v-model="journey.action[selectedActionIndex].msg_start_one" disabled hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp" v-if="journey.action[selectedActionIndex].msg_start_one == 0">
                                                <div class="group_item">
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="journey.action[selectedActionIndex].selected_email_order"
                                                            :options="sequenceEmails"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="order"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="throttle_msg" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Throttle for contact timezone</h5>
                                                <input type="checkbox" id="throttle_msg" :true-value="1" :false-value="0" v-model="journey.action[selectedActionIndex].sms_throttle_tz" disabled hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 1">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select Sequence</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="sequences"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 2 || journey.action_type[selectedActionIndex] == 3">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select tag</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        :group-select="true"
                                                        :options="tagsGroup"
                                                        :groups="true"
                                                        group-label="type"
                                                        group-options="tags"
                                                        :group-hide-empty="true"
                                                        placeholder="Select Tags"
                                                        disabled
                                                    >
                                                        <template v-slot:grouplabel="{ group }">
                                                            <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                            <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                              {{ option.name }}
                                                              <span
                                                                v-if="!disabled"
                                                                class="multiselect-tag-remove"
                                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                                              >
                                                                  <span class="multiselect-tag-remove-icon"></span>
                                                              </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-5" v-if="journey.action_type[selectedActionIndex] == 4">
                                        <sending-method v-model="journey.action[selectedActionIndex].send_method" />
                                        <div class="mt-5" v-if="journey.action[selectedActionIndex].send_method == 1 || journey.action[selectedActionIndex].send_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Email Recipient</label>
                                                    <div class="field_wpr m-0">
                                                        <vue-tags-input :tags="journey.action[selectedActionIndex].email_to" :read-only="true" :limit="1" :add-tag-on-blur="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <email-component v-model="notificationEmail" :errors="{}" preview-module="email" ref="notification-email-component" is-full-view />
                                        </div>
                                        <div class="mt-5" v-if="journey.action[selectedActionIndex].send_method == 2 || journey.action[selectedActionIndex].send_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">SMS Recipient</label>
                                                    <div class="field_wpr m-0">
                                                        <vue-tags-input :tags="journey.action[selectedActionIndex].sms_to" :read-only="true" :limit="1" :add-tag-on-blur="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <sms-component v-model="journey.action[selectedActionIndex].sms_content" :media="journey.action[selectedActionIndex].sms_media" />
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 5 || journey.action_type[selectedActionIndex] == 14">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select playbook</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="playbooks"
                                                        placeholder="Select One.."
                                                        label="title"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <sending-method v-model="journey.send_method[selectedActionIndex]" v-if="journey.action_type[selectedActionIndex] == 5" />
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 6 || journey.action_type[selectedActionIndex] == 27">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select page</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="pages"
                                                        placeholder="Select One.."
                                                        label="title"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <sending-method v-model="journey.send_method[selectedActionIndex]" v-if="journey.action_type[selectedActionIndex] == 6" />
                                    </div>
                                    <div v-if="journey.action_type[selectedActionIndex] == 7">
                                        <sms-component v-model="journey.action[selectedActionIndex].sms_content" :media="journey.action[selectedActionIndex].sms_media" />
                                        <label for="throttle_sms" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Throttle for contact timezone</h5>
                                            <input type="checkbox" id="throttle_sms" :true-value="1" :false-value="0" v-model="journey.action[selectedActionIndex].sms_throttle_tz" disabled hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 8">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select template</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="templates"
                                                        placeholder="Select One.."
                                                        label="title"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <sending-method v-model="journey.send_method[selectedActionIndex]" />
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 9 || journey.action_type[selectedActionIndex] == 28">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select form</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="forms"
                                                        placeholder="Select One.."
                                                        label="survey_name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <sending-method v-model="journey.send_method[selectedActionIndex]" v-if="journey.action_type[selectedActionIndex] == 9" />
                                    </div>
                                    <div v-if="journey.action_type[selectedActionIndex] == 10">
                                        <email-component v-model="singleEmail" :errors="{}" preview-module="email" ref="email-component" is-full-view />
                                    </div>
                                    <div v-if="journey.action_type[selectedActionIndex] == 11">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Points Awarded</label>
                                                <div class="field_wpr">
                                                    <input type="number" min="0" v-model="journey.action[selectedActionIndex].points" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <label for="throttle_sms" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Subscriber notifications</h5>
                                            <input type="checkbox" id="throttle_sms" :true-value="1" :false-value="0" v-model="journey.action[selectedActionIndex].subscriber_notification" disabled hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="edit_section mt-3" v-if="journey.action[selectedActionIndex].subscriber_notification">
                                            <sending-method v-model="journey.action[selectedActionIndex].notification_method" />
                                        </div>
                                        <div class="form_grp mt-3">
                                            <div class="group_item">
                                                <label class="input_label">You Just Earn {{ journey.action[selectedActionIndex].points }} Points For {{ journey.action[selectedActionIndex].reward_reason }}</label>
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="Type Here..." v-model="journey.action[selectedActionIndex].reward_reason" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 12">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select journey</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="journeys"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 13">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select segment</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :groups="true"
                                                        :searchable="true"
                                                        :options="statusesGroup"
                                                        valueProp="value"
                                                        label="title"
                                                        placeholder="Select segment"
                                                        :group-select="false"
                                                        group-label="type"
                                                        group-options="items"
                                                        :group-hide-empty="true"
                                                        disabled
                                                    >
                                                        <template v-slot:grouplabel="{ group }">
                                                            <span class="status-tabs-header">{{ group.type }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.title }}</span>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 16 || journey.action_type[selectedActionIndex] == 17 || journey.action_type[selectedActionIndex] == 18">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select pipeline</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].pipeline"
                                                        :options="pipelines"
                                                        placeholder="Select One.."
                                                        label="title"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3" v-if="selectStage">
                                            <div class="group_item">
                                                <label class="input_label">Which stage should contact be added to</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].pipeline_stage"
                                                        :options="pipelineStages"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 19 || journey.action_type[selectedActionIndex] == 21 || journey.action_type[selectedActionIndex] == 23 || journey.action_type[selectedActionIndex] == 24">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select task</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].task"
                                                        :options="tasks"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 20 || journey.action_type[selectedActionIndex] == 22">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select task</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].task"
                                                        :options="userTasks"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select user</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex].user"
                                                        :options="organizationUsers"
                                                        value-prop="id"
                                                        label="full_name"
                                                        :searchable="true"
                                                        placeholder="Select One.."
                                                        autocomplete="nofill"
                                                    >
                                                        <template v-slot:singlelabel="{ value }">
                                                            <div class="multiselect-single-label">
                                                              <img class="character-label-icon" :src="value.profile_pic"> {{ value.full_name }}
                                                            </div>
                                                        </template>

                                                        <template v-slot:option="{ option }">
                                                            <div class="multiselect-avatar">
                                                                <img class="character-option-icon" :src="option.profile_pic">
                                                            </div>
                                                            <div class="multiselect-title-content">
                                                                <div class="multiselect-title">{{ option.full_name }}</div>
                                                                <div class="multiselect-sub-title">{{ option.email }}</div>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 25 || journey.action_type[selectedActionIndex] == 26">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select progress scenario</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="progressTrackings"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="journey.action_type[selectedActionIndex] == 29 || journey.action_type[selectedActionIndex] == 30">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select habit scenario</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action[selectedActionIndex]"
                                                        :options="habitTrackings"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Optional Note</label>
                                        <div class="field_wpr">
                                            <input type="text" placeholder="Add A Note" v-model="journey.action_title[selectedActionIndex]" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr" v-if="selectedDelayIndex != null">
                                <div class="form_grp" v-if="['days', 'hours', 'minutes', 'weeks', 'months'].includes(journey.action_delay[selectedDelayIndex].duration_unit)">
                                    <div class="group_item">
                                        <label class="input_label">Wait</label>
                                        <div class="field_wpr">
                                            <input type="number" min="0" v-model="journey.action_delay[selectedDelayIndex].duration" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label" v-if="['specific_day', 'specific_week', 'specific_month', 'specific_year'].includes(journey.action_delay[selectedDelayIndex].duration_unit)">Wait</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="journey.action_delay[selectedDelayIndex].duration_unit"
                                                :options="delayTypes"
                                                disabled
                                            ></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_year'">
                                    <div class="group_item">
                                        <label class="input_label">Wait until current date of the year is</label>
                                        <div class="field_wpr">
                                            <datepicker v-model="journey.action_delay[selectedDelayIndex].sched_dt" autoApply placeholder="MM/DD/YYYY" format="dd-MM-yyyy" :enableTimePicker="false" disabled></datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_day' || journey.action_delay[selectedDelayIndex].duration_unit == 'specific_year'">
                                    <div class="form_grp" >
                                        <div class="group_item">
                                            <label class="input_label" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_day'">Wait until current time of the day is</label>
                                            <label class="input_label" v-if="journey.action_delay[selectedDelayIndex].duration_unit != 'specific_day'">Time</label>
                                            <div class="group-col-3" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_day'">
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_tod_hour"
                                                        :options="hours"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_tod_minute"
                                                        :options="minutes"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_tod_ampm"
                                                        :options="clocks"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                            <div class="group-col-3" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_year'">
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_dt_hour"
                                                        :options="hours"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_dt_minute"
                                                        :options="minutes"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="journey.action_delay[selectedDelayIndex].sched_dt_ampm"
                                                        :options="clocks"
                                                        disabled
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Timezone</label>
                                            <div class="field_wpr" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_year'">
                                                <multiselect
                                                    v-model="journey.action_delay[selectedDelayIndex].sched_dt_timezone"
                                                    :options="timezones"
                                                    valueProp="timezone"
                                                    label="timezone"
                                                    :searchable="true"
                                                    disabled
                                                >
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ `(${ option.abbr} ${ option.utc_offset_str}) ${ option.timezone}` }}</span>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <div class="field_wpr" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_day'">
                                                <multiselect
                                                    v-model="journey.action_delay[selectedDelayIndex].sched_time_timezone"
                                                    :options="timezones"
                                                    valueProp="timezone"
                                                    label="timezone"
                                                    :searchable="true"
                                                    disabled
                                                >
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ `(${ option.abbr} ${ option.utc_offset_str}) ${ option.timezone}` }}</span>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="extra_info mt-5" v-if="journey.action_delay[selectedDelayIndex].duration_unit == 'specific_day'">
                                        <p>Subscribers added before {{ journey.action_delay[selectedDelayIndex].tod_hour }}:{{ journey.action_delay[selectedDelayIndex].tod_minute }} {{ journey.action_delay[selectedDelayIndex].tod_ampm }} will be triggered at {{ journey.action_delay[selectedDelayIndex].tod_hour }}:{{ journey.action_delay[selectedDelayIndex].tod_minute }} {{ journey.action_delay[selectedDelayIndex].tod_ampm }} same day.
                                        <br/><br/>All subscribers added after {{ journey.action_delay[selectedDelayIndex].tod_hour }}:{{ journey.action_delay[selectedDelayIndex].tod_minute }} {{ journey.action_delay[selectedDelayIndex].tod_ampm }} will be triggered at {{ journey.action_delay[selectedDelayIndex].tod_hour }}:{{ journey.action_delay[selectedDelayIndex].tod_minute }} {{ journey.action_delay[selectedDelayIndex].tod_ampm }} the following day.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr" v-if="selectedConditionIndex != null">
                                  <label for="any_rule" class="switch_option capsule_btn">
                                      <h5>Any Rule<span>Select one or a few conditions where ANY rule can match the criteria</span></h5>
                                      <input type="radio" id="any_rule" :value="0" v-model="conditionRule" disabled hidden>
                                      <div><span></span></div>
                                  </label>
                                  <label for="all_rule" class="switch_option capsule_btn">
                                      <h5>All Rules<span>Select one or a few conditions where ALL rules must match the criteria.</span></h5>
                                      <input type="radio" id="all_rule" :value="1" v-model="conditionRule" disabled hidden>
                                      <div><span></span></div>
                                  </label>
                                  <div class="condition_wpr">
                                      <div class="edit_section mt-3 mb-3">
                                          <div class="form_grp">
                                              <div class="group_item">
                                                  <label class="input_label">Condition</label>
                                                  <div class="field_wpr">
                                                      <multiselect
                                                          v-model="journey.action_delay[selectedConditionIndex].condition"
                                                          :options="conditions"
                                                          placeholder="Select One.."
                                                          disabled
                                                      ></multiselect>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="form_grp">
                                              <div class="group_item">
                                                  <div class="field_wpr">
                                                      <multiselect
                                                          v-model="journey.action_delay[selectedConditionIndex].condition_tags"
                                                          mode="tags"
                                                          label="name"
                                                          value-prop="id"
                                                          :searchable="true"
                                                          :group-select="true"
                                                          :options="tagsGroup"
                                                          :groups="true"
                                                          group-label="type"
                                                          group-options="tags"
                                                          :group-hide-empty="true"
                                                          placeholder="Select Tags"
                                                          disabled
                                                      >
                                                          <template v-slot:grouplabel="{ group }">
                                                              <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                          </template>
                                                          <template v-slot:option="{ option }">
                                                              <span>{{ option.name }}</span>
                                                          </template>
                                                          <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                              <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                {{ option.name }}
                                                                <span
                                                                  v-if="!disabled"
                                                                  class="multiselect-tag-remove"
                                                                  @mousedown.prevent="handleTagRemove(option, $event)"
                                                                >
                                                                    <span class="multiselect-tag-remove-icon"></span>
                                                                </span>
                                                              </div>
                                                          </template>
                                                      </multiselect>
                                                  </div>
                                              </div>
                                          </div>
                                          <button type="button" class="remove_btn pointer" @click="deleteTrigger($event)"><i class="fas fa-trash-alt"></i></button>
                                      </div>
                                  </div>
                                  <button type="button" class="add_btn pointer" @click="addConditionTrigger()"><i class="fas fa-plus"></i>Add Another Condition</button>
                              </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeTriggerActionPanel()">Cancel</button>
                        <button type="button" class="btn save_btn" @click="editWarning()">Save</button>
                    </div>
                </form>
            </div>
        </div>
        <email-sms-preview v-model="fullPreviewMessage" :action="journey.action" :action-type="journey.action_type" :index="selectedActionIndex" />
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Draggable from 'vuedraggable'
    import Multiselect from '@vueform/multiselect'
    import SendingMethod from '@/components/SendingMethod'
    import EmailComponent from '@/components/EmailComponent'
    import SmsComponent from '@/components/SmsComponent'
    import VueTagsInput from 'vue3-tags-input'
    import EmailSmsPreview from '@/pages/journey/components/EmailSmsPreviewOld'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Journey Wizard Old',

        data () {
            return {
                selectedTriggerIndex: 0,
                triggerScale: 0,
                selectedActionIndex: null,
                selectedDelayIndex: null,
                selectedConditionIndex: null,
                statusArray: [],
                triggers: [
                    { value: 0, label: 'Subscribe to sequence' },
                    { value: 1, label: 'Completes a sequence' },
                    { value: 2, label: 'Tag added' },
                    { value: 3, label: 'Tag removed' },
                    { value: 4, label: 'Clicked the link' },
                    { value: 5, label: 'Submitted a form' },
                    { value: 6, label: 'Assigned a task' },
                    { value: 7, label: 'Completed a task' },
                    { value: 8, label: 'Submitted a progress report' },
                    { value: 9, label: 'Submitted a habit report' },
                ],
                triggerLinks: [],
                selectedTriggerLink: { id: 0 },
                actions: [
                    { value: 0, label: 'Start a sequence' },
                    { value: 1, label: 'Stop a sequence' },
                    { value: 2, label: 'Add a tag' },
                    { value: 3, label: 'Remove a tag' },
                    { value: 4, label: 'Send a 3rd party notification' },
                    { value: 5, label: 'Assign a playbook' },
                    { value: 14, label: 'Restrict a playbook' },
                    { value: 6, label: 'Send to a page' },
                    { value: 27, label: 'Restrict a page in portal' },
                    { value: 7, label: 'Send an SMS' },
                    { value: 8, label: 'Send a template' },
                    { value: 9, label: 'Send a form or agreement' },
                    { value: 28, label: 'Restrict a form in portal' },
                    { value: 10, label: 'Send a single email' },
                    { value: 11, label: 'Add reward points' },
                    { value: 12, label: 'Terminate a journey' },
                    { value: 13, label: 'Switch contact between tabs' },
                    { value: 16, label: 'Add contact to a pipeline' },
                    { value: 17, label: 'Switch contact between pipeline stages' },
                    { value: 18, label: 'Remove contact from a pipeline' },
                    { value: 19, label: 'Assign task to contact' },
                    { value: 20, label: 'Assign task to user' },
                    { value: 21, label: 'Unassign task from contact' },
                    { value: 22, label: 'Unassign task from user' },
                    { value: 23, label: 'Mark task complete' },
                    { value: 24, label: 'Mark task incomplete' },
                    { value: 25, label: 'Start a progress tracking' },
                    { value: 26, label: 'Stop a progress tracking' },
                    { value: 29, label: 'Start a habit tracking' },
                    { value: 30, label: 'Stop a habit tracking' },
                ],
                delayTypes: [
                    { value: 'minutes', label: 'Minute(s)' },
                    { value: 'hours', label: 'Hour(s)' },
                    { value: 'days', label: 'Day(s)' },
                    { value: 'weeks', label: 'Week(s)' },
                    { value: 'months', label: 'Month(s)' },
                    { value: 'specific_day', label: 'Specific time of the day' },
                    { value: 'specific_week', label: 'Specific day of the week' },
                    { value: 'specific_month', label: 'Specific day of the month' },
                    { value: 'specific_year', label: 'Specific day of the year' },
                ],
                journey: {},
                hours: [
                    { value: 0, label: '00' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9' },
                    { value: 10, label: '10' },
                    { value: 11, label: '11' },
                    { value: 12, label: '12' }
                ],
                minutes: [
                    { value: '00', label: '00' },
                    { value: 1, label: '01' },
                    { value: 2, label: '02' },
                    { value: 3, label: '03' },
                    { value: 4, label: '04' },
                    { value: 5, label: '05' },
                    { value: 6, label: '06' },
                    { value: 7, label: '07' },
                    { value: 8, label: '08' },
                    { value: 9, label: '09' },
                    { value: 10, label: '10' },
                    { value: 11, label: '11' },
                    { value: 12, label: '12' },
                    { value: 13, label: '13' },
                    { value: 14, label: '14' },
                    { value: 15, label: '15' },
                    { value: 16, label: '16' },
                    { value: 17, label: '17' },
                    { value: 18, label: '18' },
                    { value: 19, label: '19' },
                    { value: 20, label: '20' },
                    { value: 21, label: '21' },
                    { value: 22, label: '22' },
                    { value: 23, label: '23' },
                    { value: 24, label: '24' },
                    { value: 25, label: '25' },
                    { value: 26, label: '26' },
                    { value: 27, label: '27' },
                    { value: 28, label: '28' },
                    { value: 29, label: '29' },
                    { value: 30, label: '30' },
                    { value: 31, label: '31' },
                    { value: 32, label: '32' },
                    { value: 33, label: '33' },
                    { value: 34, label: '34' },
                    { value: 35, label: '35' },
                    { value: 36, label: '36' },
                    { value: 37, label: '37' },
                    { value: 38, label: '38' },
                    { value: 39, label: '39' },
                    { value: 40, label: '40' },
                    { value: 41, label: '41' },
                    { value: 42, label: '42' },
                    { value: 43, label: '43' },
                    { value: 44, label: '44' },
                    { value: 45, label: '45' },
                    { value: 46, label: '46' },
                    { value: 47, label: '47' },
                    { value: 48, label: '48' },
                    { value: 49, label: '49' },
                    { value: 50, label: '50' },
                    { value: 51, label: '51' },
                    { value: 52, label: '52' },
                    { value: 53, label: '53' },
                    { value: 54, label: '54' },
                    { value: 55, label: '55' },
                    { value: 56, label: '56' },
                    { value: 57, label: '57' },
                    { value: 58, label: '58' },
                    { value: 59, label: '59' },
                ],
                clocks: [
                    { value: 'am', label: 'AM' },
                    { value: 'pm', label: 'PM' }
                ],
                conditionRule: 1,
                conditions: [
                    { value: 1, label: 'If tag is present skip to the next step' },
                    { value: 2, label: 'If tag is present skip to a specific step' },
                    { value: 3, label: 'If tag is absent skip to next step' },
                    { value: 4, label: 'If tag is absent skip to a specific step' },
                ],
                sequenceEmails: [],
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                singleEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                selectStage: false,
                fullPreviewMessage: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Draggable,
            Multiselect,
            SendingMethod,
            VueTagsInput,
            SmsComponent,
            EmailComponent,
            EmailSmsPreview,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;
                    const totalTrigger = vm.selectedJourney.trigger.length;

                    vm.triggerScale = Math.round(totalTrigger / 2) - 1;
                    vm.journey = JSON.parse(JSON.stringify(vm.selectedJourney));
                }
            },

            statuses (statuses) {
                const vm = this;

                vm.parseStatusTitle(statuses);
            },

            selectedTriggerIndex (index) {
                const vm = this;

                if (index != null && vm.journey.trigger_type[index] == 4) {
                    vm.selectedTriggerLink = { id: 0 };

                    setTimeout(function () {
                        let link     = {};
                        const idx = vm.journeyLinks.findIndex(x => x.id == vm.journey.trigger[index]);

                        if (idx != -1) {
                            link = vm.journeyLinks[idx];
                        }

                        const triggerLink = new URL(link.url);

                        for (let i = 0; i < vm.triggerLinks.length; i++) {
                              for (let j = 0; j < vm.triggerLinks[i].options.length; j++) {
                                  if (triggerLink.pathname == vm.slugifyLink(vm.triggerLinks[i].options[j])) {
                                      const origin = (new URL(process.env.VUE_APP_API_URL)).origin;
                                      vm.selectedTriggerLink =  vm.triggerLinks[i].options[j];
                                      vm.selectedTriggerLink.public_url = origin + vm.slugifyLink(vm.triggerLinks[i].options[j]);
                                      break;
                                  }
                              }
                        }
                    }, 300);
                }
            },

            selectedActionIndex (index) {
                const vm = this;
                vm.selectStage = false;

                if (index != null && vm.journey.action_type[index] == 0) {
                    const action = vm.journey.action[index];

                    vm.resetSequenceOrders(action.seq_id);
                } else if (index != null && vm.journey.action_type[index] == 4) {
                    vm.journey.action[index].send_method = parseInt(vm.journey.action[index].send_method);

                    if (typeof vm.journey.action[index].sms_to == 'string') {
                        vm.journey.action[index].sms_to = vm.journey.action[index].sms_to.split(',');
                    }

                    if (typeof vm.journey.action[index].email_to == 'string') {
                        vm.journey.action[index].email_to = vm.journey.action[index].email_to.split(',');
                    }

                    vm.notificationEmail = {
                                                defaultHandler: true,
                                                subject: vm.journey.action[index].email_subject,
                                                email: vm.journey.action[index].email_content,
                                                is_email_logo: vm.journey.action[index].is_email_logo ? 1 : 0,
                                                is_signature: vm.journey.action[index].is_signature,
                                                signature_id: vm.journey.action[index].signature_id,
                                                show_email_logo: vm.journey.action[index].is_email_logo ? 1 : 0,
                                                email_logo: vm.journey.action[index].email_logo,
                                            };
                } else if (index != null && vm.journey.action_type[index] == 10) {
                    vm.singleEmail  = {
                                          defaultHandler: true,
                                          subject: vm.journey.action[index].email_subject,
                                          email: vm.journey.action[index].email_content,
                                          is_email_logo: vm.journey.action[index].is_email_logo ? 1 : 0,
                                          is_signature: vm.journey.action[index].is_signature,
                                          signature_id: vm.journey.action[index].signature_id,
                                          show_email_logo: vm.journey.action[index].is_email_logo ? 1 : 0,
                                          email_logo: vm.journey.action[index].email_logo,
                                      };
                } else if (index != null && vm.journey.action_type[index] == 11) {
                    vm.journey.action[index].notification_method = parseInt(vm.journey.action[index].notification_method);
                } else if (index != null && (vm.journey.action_type[index] == 16 || vm.journey.action_type[index] == 17)) {
                    if (vm.journey.action[index].pipeline) {
                        vm.selectStage = true;
                        vm.getPipelineStages(vm.journey.action[index].pipeline);
                    } else {
                        vm.selectStage = false;
                    }
                }
            },

            selectedDelayIndex (index) {
                const vm = this;

                if (index != null) {
                    const delay = vm.journey.action_delay[index];

                    if (delay.delay_method == 2) {
                        vm.journey.action_delay[index].duration_unit = 'specific_year';
                    } else if (delay.delay_method == 3) {
                        vm.journey.action_delay[index].duration_unit = 'specific_day';
                    }
                }
            },

            selectedConditionIndex (index) {
                const vm = this;

                if (index != null) {
                    const delay = vm.journey.action_delay[index];

                    if (typeof vm.journey.action_delay[index].condition_tags == 'string') {
                        vm.journey.action_delay[index].condition_tags = vm.journey.action_delay[index].condition_tags.split(',');
                    }
                }
            },
        },

        computed: mapState({
            playbooks: state => state.playbookModule.allPlaybooks,
            pages: state => state.pageModule.allPages,
            forms: state => state.formModule.allForms,
            journeys: state => state.journeyModule.allJourneys,
            tagsGroup: state => state.tagModule.tagsGroup,
            tags: state => state.tagModule.tags,
            pipelines: state => state.pipelineModule.allPipelines,
            sequences: state => state.sequenceModule.allSequences,
            progressTrackings: state => state.progressTrackingModule.allProgressTrackings,
            habitTrackings: state => state.habitTrackingModule.allHabitTrackings,
            tasks: state => state.taskModule.allTasks,
            userTasks: state => state.taskModule.allUserTasks,
            selectedJourney: state => state.journeyModule.selectedJourney,
            journeyLinks: state => state.journeyModule.journeyLinks,
            templates: state => state.templateModule.allTemplates,
            statuses: state => state.contactModule.statusList,
            statusesGroup: state => state.contactModule.statusesGroup,
            links: state => state.commonModule.definedLinks,
            timezones: state => state.commonModule.timezones,
            pipelineStages: state => state.pipelineModule.pipelineStages,
            organizationUsers: state => state.authModule.organizationUsers,
        }),

        mounted () {
            const vm = this;

            setTimeout(function () {
                if (vm.tagsGroup.length == 0) {
                    vm.getTags();
                }

                if (vm.pipelines.length == 0) {
                    vm.getAllPipelines({ select: 'id,name' });
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences({ select: 'id,name' });
                }

                if (vm.progressTrackings.length == 0) {
                    vm.getAllProgressTrackings({ select: 'id,name' });
                }

                if (vm.habitTrackings.length == 0) {
                    vm.getAllHabitTrackings({ select: 'id,name' });
                }

                if (vm.tasks.length == 0) {
                    vm.getAllTasks({ select: 'id,name' });
                }

                if (vm.userTasks.length == 0) {
                    vm.getAllUserTasks({ select: 'id,name' });
                }

                if (vm.playbooks.length == 0) {
                    vm.getAllPlaybooks({ select: 'id,title' });
                }

                if (vm.pages.length == 0) {
                    vm.getAllPages({ select: 'id,title,is_private' });
                }

                if (vm.forms.length == 0) {
                    vm.getAllForms({ select: 'id,survey_name,is_private' });
                }

                if (vm.journeys.length == 0) {
                    vm.getAllJourneys({ select: 'id,name,action,action_title,action_type' });
                }

                if (vm.journeyLinks.length == 0) {
                    vm.getJourneyLinks({ select: 'id,name,url' });
                }

                if (vm.templates.length == 0) {
                    vm.getAllTemplates({ select: 'id,title' });
                }

                if (vm.statuses.length == 0) {
                    vm.getStatuses();
                }

                if (vm.timezones.length == 0) {
                    vm.getTimezones();
                }

                if (vm.organizationUsers.length == 0) {
                    vm.getOrganizationUsers();
                }

                vm.parseStatusTitle(vm.statuses);

                const triggerLinks = [];

                vm.links.forEach((link) => {
                    if (link.label != 'Trigger Links') {
                        triggerLinks.push(link);
                    }
                });

                vm.triggerLinks = triggerLinks;
            }, 1500);
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                getAllPipelines: 'pipelineModule/getAllPipelines',
                getAllSequences: 'sequenceModule/getAllSequences',
                getAllProgressTrackings: 'progressTrackingModule/getAllProgressTrackings',
                getAllHabitTrackings: 'habitTrackingModule/getAllHabitTrackings',
                getAllTasks: 'taskModule/getAllTasks',
                getAllUserTasks: 'taskModule/getAllUserTasks',
                getAllPlaybooks: 'playbookModule/getAllPlaybooks',
                getAllPages: 'pageModule/getAllPages',
                getAllForms: 'formModule/getAllForms',
                getAllJourneys: 'journeyModule/getAllJourneys',
                getJourneyLinks: 'journeyModule/getJourneyLinks',
                getAllTemplates: 'templateModule/getAllTemplates',
                getStatuses: 'contactModule/getStatuses',
                getTimezones: 'commonModule/getTimezones',
                getPipelineStages: 'pipelineModule/getPipelineStages',
                getOrganizationUsers: 'authModule/getOrganizationUsers',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            triggerTitle (code) {
                const data = [];
                data[0] = 'Contact subscribe to a sequence';
                data[1] = 'Contact completes a sequence';
                data[2] = 'Tag is added to a contact';
                data[3] = 'Tag is removed from a contact';
                data[4] = 'Contact clicks a link';
                data[5] = 'Contact submits a form';
                data[6] = 'Task assigned to contact';
                data[7] = 'Task completed by contact';
                data[8] = 'Progress submitted by contact';
                data[9] = 'Habits submitted by contact';

                return data[code] ? data[code] : 'Set Trigger';
            },

            triggerName (data, type) {
                const vm = this;

                try {
                    if (type == 0 || type == 1) {
                        return vm.sequenceNameById(data);
                    } else if (type == 2 || type == 3) {
                        return vm.tagNameById(data);
                    } else if (type == 5) {
                        return vm.formNameById(data.trigger);
                    } else if (type == 6 || type == 7) {
                        return vm.taskNameById(data);
                    } else if (type == 8) {
                        return vm.progressNameById(data);
                    } else if (type == 9) {
                        return vm.habitNameById(data);
                    }
                } catch (error) {
                    return null;
                }
            },

            parseStatusTitle (statuses) {
                const vm = this;

                for (let index in statuses) {
                    vm.statusArray[statuses[index].value] = statuses[index].title;
                }
            },

            actionName (data, type) {
                const vm = this;

                try {
                    if (type == 0 || type == 1) {
                        if (typeof data === 'number') {
                            return vm.sequenceNameById(data);
                        } else {
                            return vm.sequenceNameById(data.seq_id);
                        }
                    } else if (type == 2 || type == 3) {
                        return vm.tagNameById(data);
                    } else if (type == 5 || type == 14) {
                        return vm.playbookNameById(data);
                    } else if (type == 6 || type == 27) {
                        return vm.pageNameById(data);
                    } else if (type == 7) {
                        return data.sms_content;
                    } else if (type == 8) {
                        return vm.templateNameById(data);
                    } else if (type == 9 || type == 28) {
                        return vm.formNameById(data);
                    } else if (type == 11) {
                        return `You have earned ${data.points} points for ${data.reward_reason}`;
                    } else if (type == 12) {
                        return vm.journeyNameById(data);
                    } else if (type == 13) {
                        return Helper.statusName(data, vm.statusArray);
                    } else if (type == 16 || type == 17) {
                        return vm.pipelineNameById(data.pipeline);
                    } else if (type == 18) {
                        return vm.pipelineNameById(data.pipeline);
                    } else if (type == 19 || type == 21 || type == 23 || type == 24) {
                        return vm.taskNameById(data.task);
                    } else if (type == 20 || type == 22) {
                        return vm.userTaskNameById(data.task);
                    } else if (type == 25 || type == 26) {
                        return vm.progressNameById(data);
                    } else if (type == 29 || type == 30) {
                        return vm.habitNameById(data);
                    }
                } catch (error) {
                    return null;
                }
            },

            playbookNameById (id) {
                const vm = this;
                const index = vm.playbooks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.playbooks[index].title;
            },

            pageNameById (id) {
                const vm = this;
                const index = vm.pages.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.pages[index].title;
            },

            templateNameById (id) {
                const vm = this;
                const index = vm.templates.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.templates[index].title;
            },

            pipelineNameById (id) {
                const vm = this;
                const index = vm.pipelines.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.pipelines[index].title;
            },

            journeyNameById (id) {
                const vm = this;
                const index = vm.journeys.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.journeys[index].name;
            },

            sequenceNameById (id) {
                const vm = this;
                const index = vm.sequences.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.sequences[index].name;
            },

            tagNameById (id) {
                const vm = this;
                const index = vm.tags.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.tags[index].name;
            },

            formNameById (id) {
                const vm = this;
                const index = vm.forms.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.forms[index].survey_name;
            },

            taskNameById (id) {
                const vm = this;
                const index = vm.tasks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.tasks[index].name;
            },

            userTaskNameById (id) {
                const vm = this;
                const index = vm.userTasks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.userTasks[index].name;
            },

            progressNameById (id) {
                const vm = this;
                const index = vm.progressTrackings.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.progressTrackings[index].name;
            },

            habitNameById (id) {
                const vm = this;
                const index = vm.habitTrackings.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.habitTrackings[index].name;
            },

            triggerUrlHtml (id) {
                const vm    = this;
                let html    = '';
                let link     = {};
                const index = vm.journeyLinks.findIndex(x => x.id == id);

                if (index != -1) {
                    link = vm.journeyLinks[index];
                }

                if (link.name) {
                    html += `<p>${ link.name }</p>`
                }

                if (link.url) {
                    html += `<a>${ link.url }</a>`
                }

                if (!link.name && !link.url) {
                    html += `<h4>Define Trigger</h4>`
                }

                return html;
            },

            editWarning (type = 'trigger') {
                let options = Helper.swalWarningOptions('Oops!', 'You cannot add any actions, delays, or conditions to classic OM Journeys. You can recreate in Thrive Coach Journeys.');

                Swal.fire(options);
            },

            deleteTrigger () {
                const options = Helper.swalWarningOptions('Oops!', 'You cannot delete any actions, delays, or conditions to classic OM Journeys. You can recreate in Thrive Coach Journeys.');

                Swal.fire(options);
            },

            addConditionTrigger () {
                const options = Helper.swalWarningOptions('Oops!', 'You cannot add any actions, delays, or conditions to classic OM Journeys. You can recreate in Thrive Coach Journeys.');

                Swal.fire(options);
            },

            addAction (e) {
                let elm       = e.currentTarget.parentNode;
                let allelm    = document.querySelectorAll('.step_type');
                let elPos     = elm.getBoundingClientRect();
                let scrHeight = screen.height;

                if (elm.classList.contains('active')) {
                    elm.classList.remove('active');
                    elm.style.zIndex = '1';
                } else {
                    for (let i = 0; i < allelm.length; i++) {
                        allelm[i].classList.remove('active');
                        allelm[i].classList.remove('top');
                        allelm[i].style.zIndex = '1';
                    }

                    if (elPos.y > (scrHeight - 400)) {
                        elm.classList.add('top');
                    }

                    elm.classList.add('active');
                    elm.style.zIndex = '2';
                }
            },

            selectAction (index, type) {
                const vm = this;

                vm.selectedTriggerIndex   = null;
                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;

                if (type == 'action') {
                    vm.selectedActionIndex    = index;
                } else if (type == 'delay') {
                    vm.selectedDelayIndex     = index;
                } else if (type == 'condition') {
                    vm.selectedConditionIndex = index;
                }
            },

            actionTitle (code) {
                const data = [];
                data[0] = 'Start a sequence';
                data[1] = 'Stop a sequence';
                data[2] = 'Add a tag';
                data[3] = 'Remove a tag';
                data[4] = 'Notify 3rd party';
                data[5] = 'Assign a playbook';
                data[6] = 'Send to a page';
                data[7] = 'Send an SMS';
                data[8] = 'Send a template';
                data[9] = 'Send a form or agreement';
                data[10] = 'Send a single email';
                data[11] = 'Add reward points';
                data[12] = 'Terminate a journey';
                data[13] = 'Switch tabs';
                data[14] = 'Restrict a playbook';
                data[15] = 'Assign contacts to users';
                data[16] = 'Add contact to a pipeline';
                data[17] = 'Switch contact between pipeline stages';
                data[18] = 'Remove contact from a pipeline';
                data[19] = 'Assign task to contact';
                data[20] = 'Assign task to user';
                data[21] = 'Unassign task from contact';
                data[22] = 'Unassign task from user';
                data[23] = 'Mark task complete';
                data[24] = 'Mark task incomplete';
                data[25] = 'Start a progress tracking';
                data[26] = 'Stop a progress tracking';
                data[27] = 'Restrict a page in portal';
                data[28] = 'Restrict a form in portal';
                data[29] = 'Start a habit tracking';
                data[30] = 'Stop a habit tracking';

                return data[code] ? data[code] : 'This action is blank';
            },

            delayTitle (index) {
                const vm      = this;
                const delay   = vm.journey.action_delay[index];
                let delayText = 'Set Delay';

                if (delay.delay_method == 1) {
                    delayText = `Deliver after ${delay.duration} ${delay.duration > 1 ? delay.duration_unit : delay.duration_unit.substring(0, delay.duration_unit.length - 1)} ${delay.duration_unit == 'days' ? `${delay.tod_hour}:${delay.tod_minute} ${delay.tod_ampm}` : ''}`;
                } else if (delay.delay_method == 2) {
                    delayText = `Deliver on ${delay.sched_dt} at ${delay.sched_dt_hour}:${delay.sched_dt_minute} ${delay.sched_dt_ampm}`;
                } else if (delay.delay_method == 3) {
                    delayText = `Deliver at ${delay.sched_tod_hour}:${delay.sched_tod_minute} ${delay.sched_tod_ampm}`;
                }

                return delayText;
            },

            selectTrigger (index) {
                const vm = this;

                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;
                vm.selectedTriggerIndex   = index;
            },

            closeTriggerActionPanel () {
                const vm = this;

                vm.selectedTriggerIndex   = null;
                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;
            },

            slugifyLink (link) {
                const vm = this;
                let sluggedUrl = '';

                if (link.asset_type == 'forms') {
                    sluggedUrl = '/survey/' + link.id + '/' +  vm.slugify(link.title);
                } else if (link.asset_type == 'playbooks') {
                    sluggedUrl = '/playbook/login/' + link.id + '/' + vm.slugify(link.title);
                } else if (link.asset_type == 'pages') {
                    sluggedUrl = '/page/' + link.id + '-' + vm.slugify(link.title);
                } else {
                    sluggedUrl = link.destination;
                }

                return sluggedUrl;
            },

            slugify (str) {
                let string = str;
                const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
                const to   = "aaaaaeeeeeiiiiooooouuuunc------";

                str = str.replace(/^\s+|\s+$/g, '');
                str = str.toLowerCase();

                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

                return str;
            },

            resetSequenceOrders (sequenceId) {
                const vm = this;
                const sequence = vm.sequences.find(sequence => sequence.id == sequenceId);

                vm.sequenceEmails = [];

                for (let i = 0; i < sequence.publish_messages; i++) {
                    vm.sequenceEmails.push({ name: `Published Message ${(1 + i)}`, order : i });
                }
            },
        },
    }
</script>

<style scoped>
    .multiselect-tag {
        padding: .125rem .5rem;
    }

    .trigger_wpr ul {
        display: flex;
    }

    .global_setting .tabs_content form>.action_wpr {
        padding: 25px 40px;
    }

    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .global_setting .tabs_content {
        background: #fff;
        flex: 0 1 450px;
        position: relative;
        margin-right: -450px;
        transition: all 0.5s ease-in-out;
        z-index: 3;
    }

    .global_setting .tabs_content.show {
        margin-right: 0;
    }

    .global_setting .tabs_content .close_btn {
        position: absolute;
        top: 35px;
    }

    .global_setting .tabs_content .action_header {
        border: 1px solid #e9e9e9;
    }

    .journey_wpr .step_details {
        padding: 30px 40px;
    }

    .journey_wpr .left_bar {
        padding: 30px 25px 30px 40px;
        border: 1px solid #eee;
        height: 100vh;
        overflow-y: scroll;
        position: relative;
        z-index: 2;
        background: #fafafb;
        flex: 1;
    }

    .journey_wpr .left_bar::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    .journey_wpr .left_bar::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 2px;
    }

    .trigger_wpr {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        /* min-width: 1320px; */
    }

    .trigger_wpr .trigger_card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 40px 70px 40px;
    }

    .trigger_wpr .trigger_card:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }

    .trigger_wpr .trigger_card:after {
        content: '';
        position: absolute;
        left: 0;
        top: 70px;
        right: 0;
        border-top: 1px solid #e9e9e9;
        z-index: -1;
    }

    .trigger_info {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        width: 320px;
        position: relative;
    }

    .trigger_info .icon {
        position: absolute;
        left: 15px;
        top: 12px;
        font-size: 18px;
        color: #999;
    }

    .trigger_info.active {
        border: 2px solid #bad7ff;
    }

    .trigger_info h5 {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 12px 30px;
        border-bottom: 1px solid #e9e9e9;
    }

    .trigger_info .info {
        flex: 1;
        padding: 25px 15px;
        margin: 0;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .trigger_info .info h4 {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        min-width: 200px;
    }

    .trigger_info .info h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
    }

    :deep(.trigger_info .info p) {
        margin: 5px 0;
        min-width: 200px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.trigger_info .info a) {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #448aed;
    }

    .trigger_info .info ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
        margin-top: 10px;
    }

    .trigger_info .info ul li {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }

    .journey_list {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .journey_list>li,
    .journey_list>span>li {
        border-radius: 8px;
        padding-bottom: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        padding-top: 30px;
    }

    .journey_list>li:first-child,
    .journey_list>span>li:first-child {
        padding-top: 30px;
    }

    .journey_list>li:before,
    .journey_list>span>li:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }

    .journey_list .sequence_item li:not(:last-child) {
        padding-bottom: 40px;
    }

    .journey_list li .step_info {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
    }

    .journey_list li .step_info.active {
        border: 2px solid #bad7ff;
    }

    .journey_list li .step_info.draft>*:not(button) {
        opacity: 0.5;
    }

    .journey_list li .remove_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: -25px;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .journey_list li .remove_btn i {
        color: #5a5a5a !important;
    }

    .journey_list li .step_info:hover .remove_btn {
        right: 10px;
    }

    .journey_list li .step_info.timer {
        padding: 15px 20px;
        border-radius: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 230px;
        max-width: 320px;
        position: relative;
        z-index: 1;
    }

    .journey_list li .step_info.timer ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }

    .journey_list li .step_info.timer ul li span {
        font-size: 9px;
        line-height: 10px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 2px 6px;
        border-radius: 10px;
        margin: 7px 0;
        display: block;
    }

    .journey_list li .step_info.mail {
        border-radius: 8px;
        flex: 0 0 340px;
        position: relative;
        z-index: 1;
    }

    .journey_list li .step_info.timer i {
        color: #b9b9b9;
        margin-top: 2px;
    }

    .journey_list li .step_info.timer h4 {
        flex: 1;
        padding: 0 15px;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .journey_list li .step_info.mail>i {
        font-size: 16px;
        color: #999;
        padding: 5px 0;
        position: absolute;
        left: 15px;
        top: 7px;
    }

    .journey_list li .step_info.mail h4 {
        text-align: center;
        padding: 12px 20px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #5a5a5a;
        position: relative;
        border-bottom: 1px solid #e9e9e9;
    }

    .journey_list li .step_info.mail h4:hover {
        cursor: move;
    }

    .journey_list li .step_info.mail h4 i {
        position: absolute;
        left: 20px;
        top: 13px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .sequence_list li .step_info.mail:hover h4 i {
        opacity: 1;
    }

    .journey_list li .step_info.mail .msg_cont .action_details {
        padding: 25px 15px;
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
    }

    .journey_list li .step_info.mail .msg_cont .action_details .info {
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: #5a5a5a;
        position: absolute;
        right: 10px;
        opacity: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .journey_list li .step_info:hover .msg_cont .action_details .info {
        opacity: 1;
    }

    .journey_list li .step_info .msg_cont .action_details .info .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        right: -6px;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .journey_list li .step_info:hover .msg_cont .action_details .info .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .journey_list li .step_info .msg_cont .action_details .info:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }

    .journey_list li .step_info.mail .msg_cont .action_details h4 {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        min-width: 200px;
    }

    .journey_list li .step_info.mail .msg_cont .action_details p {
        min-width: 200px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #2C3E50;
    }

    .journey_list li .step_info.mail .msg_cont .action_details ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }

    .journey_list li .step_info.mail .msg_cont .action_details ul li {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }

    .journey_list li .step_info.mail .msg_cont .mail_box,
    .journey_list li .step_info.mail .msg_cont .sms_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        max-width: 250px;
    }

    .journey_list li .step_info.mail .msg_cont .mail_box .status_area,
    .journey_list li .step_info.mail .msg_cont .sms_box .status_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .journey_list li .step_info.mail .msg_cont .mail_box .score_circle {
        width: 30px;
        height: 30px;
        padding: 2px;
    }

    .journey_list li .step_info.mail .msg_cont .mail_box .score_circle .inner_circle .score {
        font-size: 15px;
        line-height: 18px;
    }

    .journey_list li .step_info.mail .msg_cont .msg_desc {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        text-align: center;
        min-width: 200px;
        background: #f5f5f5;
    }

    .journey_list li .step_info.mail .msg_cont .msg_desc p {
        max-height: 32px;
        font-size: 11px;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .journey_list>li.branch,
    .journey_list>span>li.branch {
        padding-bottom: 0;
        /* margin-bottom: 50px; */
    }

    .branch_card {
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 8px;
        max-width: 240px;
        margin: 15px auto 25px;
        position: relative;
        z-index: 2;
    }

    .branch_card .branch_info {
        position: relative;
        overflow: hidden;
        padding: 30px 25px;
    }

    .branch_card:after {
        content: '';
        position: absolute;
        top: 100%;
        height: 25px;
        left: 50%;
        border-left: 1px solid #e9e9e9;
    }

    .branch_card span {
        width: 25px;
        height: 25px;
        background: #999;
        position: absolute;
        left: 50%;
        top: -13px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%) rotate(45deg);
    }

    .branch_card span i {
        transform: rotate(-45deg);
        color: #fff;
    }

    .branch_card.active {
        border: 2px solid #bad7ff;
    }

    .branch_card h4 {
        padding: 12px 30px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
        position: relative;
        border: 1px dashed #c9c9c9;
    }

    .branch_card h4 i {
        position: absolute;
        font-size: 16px;
        top: 12px;
        left: 25px;
        color: #b9b9b9;
    }

    .branch_card:hover .remove_btn {
        right: 10px;
    }

    .journey_list>li.branch .branch_wpr,
    .journey_list>span>li.branch .branch_wpr {
        background: #fafafb;
        position: relative;
        z-index: 1;
    }

    .journey_list li.branch .branch_wpr>ul {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 40px
    }

    .journey_list li.branch .branch_wpr>ul>li {
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .journey_list>li.branch .branch_wpr>ul>li:first-child:before,
    .journey_list>span>li.branch .branch_wpr>ul>li:first-child:before {
        content: '';
        position: absolute;
        left: 50%;
        right: -20px;
        top: 0;
        border-top: 1px solid #e9e9e9;
    }

    .journey_list>li.branch .branch_wpr>ul>li:last-child:before,
    .journey_list>span>li.branch .branch_wpr>ul>li:last-child:before {
        content: '';
        position: absolute;
        right: 50%;
        left: -20px;
        top: 0;
        border-top: 1px solid #e9e9e9;
    }

    .journey_list>li.branch .branch_wpr>ul>li,
    .journey_list>span>li.branch .branch_wpr>ul>li {
        position: relative;
        padding-top: 30px;
    }

    .journey_list>li.branch .branch_wpr>ul>li .journey_list,
    .journey_list>span>li.branch .branch_wpr>ul>li .journey_list {
        padding-bottom: 70px;
    }

    .journey_list>li.branch .branch_wpr>ul>li:after,
    .journey_list>span>li.branch .branch_wpr>ul>li:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        transform: translateX(-50%);
        border-left: 1px solid #e9e9e9;
        z-index: 0;
    }

    .journey_list>li.branch .branch_wpr>ul>li .icons,
    .journey_list>span>li.branch .branch_wpr>ul>li .icons {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        z-index: 2;
    }

    .journey_list>li.branch .branch_wpr>ul>li .icons i,
    .journey_list>span>li.branch .branch_wpr>ul>li .icons i {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
    }

    .journey_wpr .step_type {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 2;
    }

    /* .journey_wpr .step_type.trigger_add{
            position: relative;
        } */
    .journey_wpr .add_new {
        width: 40px;
        height: 40px;
        font-size: 15px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5a5a5a;
        cursor: pointer;
    }

    .add_types {
        width: 500px;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: none;
    }

    .step_type.active .add_types {
        display: block;
    }

    .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .journey_wpr .step_type.top .add_types {
        bottom: 50px;
        top: auto;
        box-shadow: 5px -20px 50px rgba(0, 0, 0, 0.1);
    }

    .journey_wpr .step_type.top .add_types:after {
        top: 100%;
        bottom: auto;
        transform: scale(1);
    }

    .add_types h3 {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        font-weight: 500;
        padding: 12px 15px;
    }

    .add_types ul {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }

    .add_types ul li {
        width: 33.333%;
        padding: 10px;
    }

    .add_types ul li i {
        font-size: 25px;
        color: #c4c4c4;
        margin-bottom: 10px;
    }

    .add_types ul li h4 {
        font-weight: 500;
    }

    .tabs_content .step_details .extra_info {
        padding: 20px;
        font-size: 12px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        display: block;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 1px solid #d9d9d9;
    }

    .tabs_content .acton_header {
        border-bottom: 1px solid #e9e9e9;
        margin: 0 -40px;
        padding: 0 45px 15px 45px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        z-index: 2;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .selected_actn_card {
        padding: 20px 30px;
        border: 1px solid #2f7eed;
        border-radius: 12px;
        margin: 15px 0;
    }

    .selected_actn_card h3 {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #2f7eed;
        margin: 0 0 5px;
    }

    .selected_actn_card h6 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .edit_section {
        margin-top: 25px;
    }

    .new_trigger {
        position: absolute;
        left: 100%;
        top: 50px;
    }

    .new_trigger.left {
        position: absolute;
        right: 100%;
        left: auto;
        top: 50px;
    }

    .notes {
        max-width: 340px;
        text-align: center;
        font-family: "Shadows", sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: #adadad;
        margin: 30px 0;
    }

    .journey_details .add_btn {
        margin: 5px 0 5px auto;
        font-size: 13px;
        line-height: 16px;
    }

    .journey_details .add_btn i {
        font-size: 11px;
    }

    .condition_wpr .divider {
        padding: 10px 0;
        position: relative;
    }

    .condition_wpr .divider:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid #e9e9e9;
    }

    .condition_wpr .divider span {
        background: #fff;
        position: relative;
        z-index: 1;
        padding-right: 10px;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .condition_wpr:last-of-type .divider {
        display: none;
    }

    .condition_wpr .remove_btn,
    .trigger_info .remove_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: 10px;
        opacity: 0;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .condition_wpr .edit_section {
        position: relative;
    }

    .condition_wpr .edit_section:hover .remove_btn,
    .trigger_info:hover .remove_btn {
        opacity: 1;
    }

    .condition_prompt .action_wpr .btn {
        margin: 0 20px;
        min-width: 80px;
        border: 0;
    }

    .condition_prompt .action_wpr .btn.leg2 {
        background: #f2a31d;
    }

    .no-before:before {
        border-top: 0px solid #e9e9e9 !important;
    }
</style>
