<template>
    <template v-if="text">
        <div class="capsule_elm border-bottom pb-2 mb-2">
            <h5>E-mail<span>Send E-mail Notification.</span></h5>
            <label :for="`email-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`email-${uuid}`" value="email" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
        <div class="capsule_elm border-bottom pb-2 mb-2">
            <h5>SMS<span>Send SMS Notification.</span></h5>
            <label :for="`sms-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`sms-${uuid}`" value="sms" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
        <div class="capsule_elm">
            <h5>Both<span>Send E-mail &amp; SMS Notification</span></h5>
            <label :for="`both-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`both-${uuid}`" value="both" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
    </template>
    <template v-else>
        <div class="capsule_elm border-bottom pb-2 mb-2">
            <h5>E-mail<span>Send E-mail Notification.</span></h5>
            <label :for="`email-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`email-${uuid}`" :value="1" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
        <div class="capsule_elm border-bottom pb-2 mb-2">
            <h5>SMS<span>Send SMS Notification.</span></h5>
            <label :for="`sms-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`sms-${uuid}`" :value="2" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
        <div class="capsule_elm">
            <h5>Both<span>Send E-mail &amp; SMS Notification</span></h5>
            <label :for="`both-${uuid}`" class="switch_option capsule_btn">
                <input type="radio" :id="`both-${uuid}`" :value="3" v-model="sendingMethod" hidden>
                <div><span></span></div>
            </label>
        </div>
    </template>
</template>

<script>
    import { uuid } from 'vue-uuid'

    export default {
        name: 'Sending Method',

        data () {
            return {
                uuid: 'uuid-'+uuid.v4(),
            }
        },

        props: {
            modelValue: {
                type: [Number, String],
                default: 1,
            },
            text: {
                type: Boolean,
                default: false
            }
        },

        emits: ['update:modelValue'],

        computed: {
            sendingMethod: {
                get () {
                    return this.modelValue;
                },
                set (method) {
                    this.$emit('update:modelValue', method);
                }
            },
        },

        mounted () {
            const vm = this;

            vm.sendingMethod = vm.modelValue;
        },
    }
</script>